import {UserInfo} from "angular-oauth2-oidc";

export class LoggedInUser {
  constructor(
    public roles: string[],
    public userInfo : UserInfo | null,
  ) {}

  // Method to check if the user has a specific role
  hasRole(role: string): boolean {
    return this.roles.includes(role);
  }

  getSub() {
    console.log('User Info is: ' + JSON.stringify(this.userInfo));
    if (this.userInfo) {
      console.log('Sub is: ' + this.userInfo['sub']);
      console.log('Sub is: ' + this.userInfo.sub);
      return this.userInfo['sub'];
    }
    return '';
  }

}
