<ion-app>
  <app-spinner></app-spinner>
  <ion-menu contentId="main-content" type="overlay" menuId="more" side="end">

    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content color="secondary">

      <ion-item (click)="accountSettings()"
                (keyup)="accountSettings()" color="secondary">
        <ion-icon name="person" slot="start" color="light"></ion-icon>
        <ion-text>Account Settings</ion-text>
      </ion-item>

      <ion-item (click)="openExternalLink('https://www.facebook.com/HomeEducatorsCompanionHEC/')"
                (keyup)="openExternalLink('https://www.facebook.com/HomeEducatorsCompanionHEC/')"
                color="secondary">
        <ion-icon name="logo-facebook" slot="start" color="light"></ion-icon>
        <ion-text>HEC on Facebook</ion-text>
      </ion-item>

      <ion-item [detail]="false" (click)="openExternalLink('https://www.homeedcompanion.com/Privacy-Policy.html')"
                (keyup)="openExternalLink('https://www.homeedcompanion.com/Contact-Us.html')"
                color="secondary">
        <ion-icon name="document-text" slot="start" color="light"></ion-icon>
        <ion-text>Privacy Policy</ion-text>
      </ion-item>

      <ion-item [detail]="false" (click)="openExternalLink('https://www.homeedcompanion.com/Terms-and-Conditions.html')"
                (keyup)="openExternalLink('https://www.homeedcompanion.com/Contact-Us.html')"
                color="secondary">
        <ion-icon name="document-text" slot="start" color="light"></ion-icon>
        <ion-text>Terms of Service</ion-text>
      </ion-item>

      <ion-item (click)="openExternalLink('https://www.homeedcompanion.com/Contact-Us.html')"
                (keyup)="openExternalLink('https://www.homeedcompanion.com/Contact-Us.html')"
                color="secondary">
        <ion-icon name="mail" slot="start" color="light"></ion-icon>
        <ion-text>Contact Us</ion-text>
      </ion-item>

    </ion-content>

  </ion-menu>

  <app-error></app-error>

  <ion-router-outlet id="main-content"></ion-router-outlet>

</ion-app>

