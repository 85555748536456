import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {SpinnerService} from "../api/spinner/spinner.service";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule
  ]
})
export class SpinnerComponent   {
  spinnerVisible$ = this.spinnerService.spinnerVisible$;

  constructor(private spinnerService : SpinnerService) { }



}
