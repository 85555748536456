import {Injectable} from '@angular/core';
import {BehaviorSubject, debounceTime} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinnerCounter = new BehaviorSubject<number>(0);
  spinnerVisible$ = this.spinnerCounter.pipe(
    debounceTime(100),
    map(counter => counter > 0)
  )

  constructor() { }

  public show() {
    this.spinnerCounter.next(this.spinnerCounter.value + 1);
  }

  public hide() {
    this.spinnerCounter.next(this.spinnerCounter.value - 1);
  }
}
